import React, { useState } from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"
import { FormattedMessage, FormattedNumber } from "react-intl"

import Layout from "src/layout"
import { useLang } from "src/layout/root"
import { Top2, Section, Row, Label } from "src/components/styled"
import Spec from "src/components/Spec"
import { COLORS, SIZES } from "src/layout/constants"
import { FormRow, SelectInput, Submit, TextInput } from "src/components/form"

import AddIcon from "src/svg/add.svg"
import RemoveIcon from "src/svg/remove.svg"
import { getSafePrice } from "src/helpers/utils"
import { uppercase } from "src/components/Name"
import functions from "src/helpers/functions"

const Product = styled(Row)`
  h3 {
    text-transform: none;
  }
  border-bottom: 1px solid ${COLORS.black};
  @media (min-width: 800px) {
    &:first-of-type {
      border-top: 1px solid ${COLORS.black};
    }
  }
`
const ImageColumn = styled.div`
  flex: 1;
  min-width: 200px;
  margin: ${SIZES.margin}px 0;
  @media (max-width: 799px) {
    margin: ${SIZES.mobileMargin}px 0;
  }
`
const ContentColumn = styled.div`
  flex: 2;
  margin: ${SIZES.margin}px 0 ${SIZES.margin}px ${SIZES.margin}px;
  min-width: 200px;
  @media (max-width: 799px) {
    margin: ${SIZES.mobileMargin}px;
  }
  display: flex;
  flex-direction: column;
  position: relative;
`
const ProductHeading = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
`
const ProductTitle = styled.div`
  flex: 1;
  h3 {
    margin: 0 0 0.5em 0;
  }
  margin-bottom: 1em;
  @media (min-width: 800px) {
    margin-right: 120px;
  }
`
const Specs = styled.div`
  @media (min-width: 800px) {
    max-width: 30em;
  }
`
const Quantity = styled.div`
  text-align: center;
  span {
    display: inline-block;
    min-width: 50px;
    font-size: 16px;
  }
  button {
    vertical-align: middle;
  }
  @media (max-width: 799px) {
    margin-top: ${SIZES.mobileMargin}px;
  }
  @media (min-width: 800px) {
    position: absolute;
    right: 0;
    top: 0;
  }
`
const Form = styled.div`
  padding: ${SIZES.mobileMargin}px 0;
  @media (min-width: 800px) {
    padding: ${SIZES.margin}px;
    max-width: ${SIZES.maxWidth}px;
    margin: 0 auto;
  }
  background-color: ${COLORS.light};
`
const CartTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  th {
    font-weight: normal;
    text-transform: uppercase;
  }
  td,
  th {
    padding: 10px;
    text-align: right;
  }
  td:first-of-type {
    width: 50%;
    text-align: left;
  }
  @media (max-width: 799px) {
    margin: ${SIZES.mobileMargin}px;
    width: calc(100% - ${SIZES.mobileMargin * 2}px) !important;
    td:first-of-type {
      padding-left: 0;
    }
    th:last-of-type,
    td:last-of-type {
      padding-right: 0;
    }
  }
`
const DeliveryRow = styled.tr`
  td {
    border-top: 2px solid ${COLORS.black};
    border-bottom: 2px solid ${COLORS.black};
    padding: 12px 10px;
  }
`
const FormBottom = styled.div`
  text-align: center;
  @media (min-width: 800px) {
    text-align: right;
    margin-top: ${SIZES.margin}px;
  }
  @media (max-width: 799px) {
    text-align: center;
    margin-bottom: ${SIZES.margin}px;
  }
`

interface Item {
  sample: Sample
  quantity: number
}

const INITIAL_VALUES = {
  company: "",
  profile: "",
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  address: "",
  postcode: "",
  city: "",
  country: "FR",
  project: "",
}

const MAX_ITEM_QUANTITY = 10

const PROFILES = [
  "Architecte",
  "Architecte d’intérieur",
  "Acousticien",
  "Designer",
  "Matériothéque",
  "Artisan",
  "Poseur",
  "Acheteur",
  "Bureau d’étude",
  "Entreprise du textile",
  "Étudiants",
  "Particuliers",
  "Autres",
]

const COUNTRIES = {
  FR: "France",
  DE: "Allemagne",
  AT: "Autriche",
  BE: "Belgique",
  BG: "Bulgarie",
  CY: "Chypre Sud", // pourquoi sud ?
  HR: "Croatie",
  DK: "Danemark",
  ES: "Espagne",
  EE: "Estonie",
  FI: "Finlande",
  GR: "Grèce",
  HU: "Hongrie",
  IE: "Irlande",
  IT: "Italie",
  LV: "Lettonie",
  LT: "Lituanie",
  LU: "Luxembourg",
  MT: "Malte",
  NL: "Pays-Bas",
  XX: "Pologne",
  PL: "Portugal",
  CZ: "République Tchèque",
  RO: "Roumanie",
  GB: "Royaume-Uni",
  SK: "Slovaquie",
  SI: "Slovénie",
  CH: "Suisse",
  SE: "Suède",
}

type Area = "FR" | "EU"

const SHIPPING: Record<Area, Record<number, number>> = {
  FR: {
    500: 7.96,
    1_000: 15.08,
    2_000: 16.13,
    5_000: 20.29,
    10_000: 34.04,
    15_000: 38.67,
    30_000: 43.92,
  },
  EU: {
    500: 13.42,
    1_000: 21.83,
    2_000: 23.63,
    5_000: 27.88,
    10_000: 48.92,
    15_000: 64.08,
    30_000: 78.05,
  },
}

const SHIPPING_DAYS: Record<Area, Record<"min" | "max", number>> = {
  FR: {
    min: 5,
    max: 10,
  },
  EU: {
    min: 7,
    max: 12,
  },
}

const VAT_RATE: Record<Area, number> = {
  FR: 0.2,
  EU: 0,
}

const getShipping = (area: Area, weight: number): number => {
  const prices = SHIPPING[area]
  for (const weightLimit in prices) {
    if (weight <= Number(weightLimit)) {
      return prices[weightLimit]
    }
  }
}

interface DataType {
  samples: { nodes: Sample[] }
}

interface PayRes {
  url: string
}

const SamplesPage: GatsbyPage<DataType> = ({ data, location }) => {
  const { getNodes } = useLang()
  const samples = getNodes<Sample>(data.samples)
  const [values, setValues] = useState(INITIAL_VALUES)
  const [sending, setSending] = useState(false)

  const [cart, setCart] = useState<Record<string, Item>>({})

  const add = (id: string) => () => {
    setCart({
      ...cart,
      [id]: {
        sample: samples.find(({ contentful_id }) => contentful_id === id),
        quantity: Math.min(MAX_ITEM_QUANTITY, (cart[id]?.quantity || 0) + 1),
      },
    })
  }

  const remove = (id: string) => () => {
    const item = cart[id]
    if (!item) {
      return
    }
    if (item.quantity > 1) {
      setCart({
        ...cart,
        [id]: {
          ...item,
          quantity: item.quantity - 1,
        },
      })
    } else {
      const { [id]: _, ...newCart } = cart
      setCart(newCart)
    }
  }

  const items = Object.values(cart)

  const weight = items.reduce((acc, { sample, quantity }) => acc + sample.weight * quantity, 0)
  const area = values.country === "FR" ? "FR" : "EU"
  const shipping = getShipping(area, weight)
  const vatMultiplier = 1 + VAT_RATE[area]

  const total = getSafePrice(items.reduce((acc, { sample, quantity }) => acc + sample.price * quantity, 0)) + shipping
  const totalWithVat = getSafePrice(total * vatMultiplier)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setValues({
      ...values,
      [event.currentTarget.name]: event.currentTarget.value,
    })
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSending(true)
    const query = new URLSearchParams(location.search)
    const res = await functions.post<PayRes>("pay", {
      mode: query.get("mode") || "live",
      values,
      shipping: getSafePrice(shipping * vatMultiplier),
      items: items.map(({ sample, quantity }) => ({
        sample: { ...sample, photo: "https:" + sample.photo.fluid.src },
        quantity,
      })),
    })
    if (res) {
      window.location.href = res.url
    } else {
      setSending(false)
    }
  }

  return (
    <Layout title="samples">
      <Top2>
        <FormattedMessage id="samples.title" tagName="h1" />
        <FormattedMessage id="contact.text" tagName="p" values={uppercase} />
      </Top2>
      <Section>
        {samples.map((sample) => {
          const price = (
            <>
              <FormattedNumber value={sample.price} style="currency" currency="EUR" />
              <FormattedMessage id="samples.withoutVat" />
            </>
          )
          return (
            <Product key={sample.contentful_id}>
              <ImageColumn>
                <Image fluid={sample.photo.fluid} />
              </ImageColumn>
              <ContentColumn>
                <ProductHeading>
                  <ProductTitle>
                    <h3>{sample.name}</h3>
                    <Label>{price}</Label>
                  </ProductTitle>
                </ProductHeading>
                <Specs>
                  <Spec id="samples.tints" description={sample.tints} />
                  <Spec id="samples.format" value={sample.format} />
                  <Spec id="samples.price" value={price} />
                  <Spec id="samples.usage" value={sample.usage} />
                </Specs>
                <Quantity>
                  <button onClick={remove(sample.contentful_id)}>
                    <RemoveIcon />
                  </button>
                  <span>{cart[sample.contentful_id]?.quantity || 0}</span>
                  <button onClick={add(sample.contentful_id)}>
                    <AddIcon />
                  </button>
                </Quantity>
              </ContentColumn>
            </Product>
          )
        })}
      </Section>
      <form onSubmit={handleSubmit}>
        <Section>
          <Form>
            <FormRow>
              <TextInput name="company" value={values.company} onChange={handleChange} />
              <SelectInput name="profile" required value={values.profile} onChange={handleChange}>
                {PROFILES.map((profile) => (
                  <option key={profile} value={profile}>
                    {profile}
                  </option>
                ))}
              </SelectInput>
            </FormRow>
            <FormRow>
              <TextInput name="firstname" required value={values.firstname} onChange={handleChange} />
              <TextInput name="lastname" required value={values.lastname} onChange={handleChange} />
            </FormRow>
            <FormRow>
              <TextInput name="email" required type="email" value={values.email} onChange={handleChange} />
              <TextInput name="phone" required type="tel" value={values.phone} onChange={handleChange} />
            </FormRow>
            <FormRow>
              <TextInput name="address" required value={values.address} onChange={handleChange} />
            </FormRow>
            <FormRow>
              <TextInput name="postcode" required value={values.postcode} onChange={handleChange} />
              <TextInput name="city" required value={values.city} onChange={handleChange} />
            </FormRow>
            <FormRow>
              <SelectInput name="country" required value={values.country} onChange={handleChange}>
                {Object.keys(COUNTRIES).map((code) => (
                  <option key={code} value={code}>
                    {COUNTRIES[code]}
                  </option>
                ))}
              </SelectInput>
            </FormRow>
            <FormRow>
              <TextInput name="project" rows={6} maxLength={500} value={values.project} onChange={handleChange} />
            </FormRow>
          </Form>
        </Section>
        {values.country && items.length > 0 && (
          <Section>
            <CartTable>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>
                    <FormattedMessage id="samples.price" />
                  </th>
                  <th>
                    <FormattedMessage id="samples.quantity" />
                  </th>
                  <th>
                    <FormattedMessage id="samples.total" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {items.map(({ sample, quantity }) => (
                  <tr key={sample.contentful_id}>
                    <td>{sample.name}</td>
                    <td>
                      <FormattedNumber value={sample.price} style="currency" currency="EUR" />
                    </td>
                    <td>{quantity}</td>
                    <td>
                      <FormattedNumber value={sample.price * quantity} style="currency" currency="EUR" />
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <DeliveryRow>
                  <td colSpan={3}>
                    <FormattedMessage id="samples.shipping" /> ({COUNTRIES[values.country]}
                    {" – "}
                    <FormattedMessage id="samples.shippingDays" values={SHIPPING_DAYS[area]} />)
                  </td>
                  <td>
                    <FormattedNumber value={shipping} style="currency" currency="EUR" />
                  </td>
                </DeliveryRow>
                <tr>
                  <td colSpan={3}>
                    <FormattedMessage id="samples.totalWithoutVat" />
                  </td>
                  <td>
                    <FormattedNumber value={total} style="currency" currency="EUR" />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <FormattedMessage id="samples.totalWithVat" />
                  </td>
                  <td>
                    <strong>
                      <FormattedNumber value={totalWithVat} style="currency" currency="EUR" />
                    </strong>
                  </td>
                </tr>
              </tfoot>
            </CartTable>

            <FormBottom>
              <Submit disabled={sending}>
                <FormattedMessage id={sending ? "input.validating" : "input.validate"} />
              </Submit>
            </FormBottom>
          </Section>
        )}
      </form>
    </Layout>
  )
}

export default SamplesPage

export const query = graphql`
  query {
    samples: allContentfulEchantillon(sort: { fields: position }) {
      nodes {
        node_locale
        contentful_id
        photo {
          fluid(maxWidth: 300, quality: 75) {
            ...GatsbyContentfulFluid
          }
        }
        name
        price
        weight
        tints
        format
        usage
      }
    }
  }
`
